// ./context/AuthContext.js

import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase"; // Assuming you have this setup
import { doc, getDoc } from "firebase/firestore";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch additional user details from Firestore
        const userDocRef = doc(db, "staff", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          // Merge authentication user data with Firestore user data
          dispatch({
            type: "LOGIN",
            payload: { ...user, ...userDocSnap.data() },
          });
        } else {
          dispatch({ type: "LOGIN", payload: user });
        }
      } else {
        dispatch({ type: "LOGOUT" });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, dispatch]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
