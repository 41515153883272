import { Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Home from '../../pages/home/Home';
import AllTransactions from '../AllTransactions/AllTransactions';
import List from '../../pages/list/List';
import Single from '../../pages/single/Single';
import UpdateUser from '../..//pages/updateUser/updateUser';
import New from '../../pages/new/New';
import PrintUser from '../PrintUser/PrintUser';
import UserHistory from '../UserHistory/UserHistory';
import { userInputs } from '../../formSource';
import Profile from '../../pages/profile/Profile';
import AddUser from '../../pages/AddUser/AddUser';
import Transfer from '../../pages/transfer/transfer';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import UserLog from '../UserLog/UserLog';

const HomeWrapper = () => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) return <Navigate to="/login" />;

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute readOnlyRoles={['audit', 'compliance']}>
              <Profile />
            </PrivateRoute>
          }
        />
        {/* <Route path="/profile" element={<Profile />} /> */}

        <Route path="/user/log/:transactionId" element={<UserLog />} />

        <Route
          path="/add-user"
          element={
            <PrivateRoute allowedRoles={['super-user', 'admin']}>
              <AddUser />
            </PrivateRoute>
          }
        />
        <Route
          path="all-transactions"
          element={<AllTransactions type="user" />}
        />

        <Route path="users">
          <Route index element={<List />} />
          <Route path=":userId" element={<Single />} />
          <Route path=":userId" element={<UserHistory />} />

          <Route
            path="edit/:userId"
            element={
              <UpdateUser inputs={userInputs} title="Update Shareholder" />
            }
          />
          <Route
            path="new"
            element={<New inputs={userInputs} title="New Shareholder" />}
          />
          <Route path="print/:id" element={<PrintUser />} />
          <Route path=":userId/transfer" element={<Transfer />} />
        </Route>
      </Routes>
    </>
  );
};

export default HomeWrapper;
