import "./addUser.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isUserAdded, setIsUserAdded] = useState(false); // State to manage success notification

  const validateFields = () => {
    let tempErrors = {};
    if (!data.name) tempErrors.name = "Please enter a name.";
    if (!data.username) tempErrors.username = "Please enter a username.";
    if (!data.email) tempErrors.email = "Please enter an email.";
    if (!data.password) tempErrors.password = "Please enter a password.";
    if (!data.role) tempErrors.role = "Please select a role.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    setIsUserAdded(false); // Reset success notification
    if (!validateFields()) return;

    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      const { password, ...restOfData } = data;
      const newUser = {
        ...restOfData,
        timeStamp: serverTimestamp(),
      };

      let collectionName = "users";
      if (["super-user", "admin", "audit", "compliance"].includes(data.role)) {
        collectionName = "staff";
      }

      await setDoc(doc(db, collectionName, res.user.uid), newUser);
      // navigate("/profile"); // Removed navigation
      setIsUserAdded(true); // Set success notification flag
      setData({}); // Optional: Reset form fields
      setErrors({}); // Reset any errors
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="content">
          {isUserAdded && (
            <div className="user-added-notification">
              User added successfully!
            </div>
          )}
          <h1>Add an Admin</h1>
          <form onSubmit={handleAdd} className="userForm">
            <div className="formInput">
              <label>Name</label>
              <input
                type="text"
                placeholder="John Doe"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="formInput">
              <label>Username</label>
              <input
                type="text"
                placeholder="Username"
                onChange={(e) => setData({ ...data, username: e.target.value })}
              />
              {errors.username && (
                <div className="error">{errors.username}</div>
              )}
            </div>
            <div className="formInput">
              <label>Email</label>
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="formInput">
              <label>Password</label>
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
              {errors.password && (
                <div className="error">{errors.password}</div>
              )}
            </div>
            <div className="formInput">
              <label>Role</label>
              <select
                onChange={(e) => setData({ ...data, role: e.target.value })}
              >
                <option value="">Select Role</option>
                <option value="super-user">Super User</option>
                <option value="admin">Admin</option>
                <option value="audit">Audit</option>
                <option value="compliance">Risk & Compliance</option>
              </select>
              {errors.role && <div className="error">{errors.role}</div>}
            </div>
            <div className="formButton">
              <button type="submit">Add User</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
