export const userInputs = [
  // {
  //   id: "username",
  //   label: "Username",
  //   type: "text",
  //   placeholder: "john_doe",
  // },
  {
    id: 'displayName',
    label: 'Name and surname',
    type: 'text',
    placeholder: 'John Doe',
  },
  {
    id: 'other',
    label: 'Other Names',
    type: 'text',
    placeholder: 'John Doe',
    isRequired: false,
  },
  {
    id: 'previous',
    label: 'Previous Names',
    type: 'text',
    placeholder: 'John Doe',
    isRequired: false,
  },
  {
    id: 'dob',
    label: 'Date of Birth',
    type: 'date',
    placeholder: 'DD/MM/YYYY',
  },
  {
    id: 'gender',
    label: 'Gender',
    type: 'select',
    options: [
      { value: '', label: 'Select Gender' }, // Initial option
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
      { value: 'group', label: 'Group' },
    ],
  },
  {
    id: 'email',
    label: 'Email',
    type: 'mail',
    placeholder: 'john_doe@gmail.com',
  },
  {
    id: 'phone',
    label: 'Phone',
    type: 'text',
    placeholder: '+233 234 567 89',
  },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
  },
  {
    id: 'address',
    label: 'Address',
    type: 'text',
    placeholder: 'Golf. St. Accra',
  },
  {
    id: 'account',
    label: 'Account',
    type: 'text',
    placeholder: '2611000061234567',
    isRequired: false,
  },
  {
    id: 'channel',
    label: 'Channel of Dividend Payment',
    type: 'select',
    options: [
      { value: '', label: 'Select Payment Channel' }, // Initial option
      { value: 'cash', label: 'Cash' },
      { value: 'bank_transfer', label: 'Bank Transfer' },
      { value: 'cheque', label: 'Cheque' },
    ],
  },

  {
    id: 'code',
    label: 'Branch Code',
    type: 'select', // Change type to "select" to identify it as a dropdown
    options: [
      { value: '', label: 'Select a branch code' }, // Initial option
      { value: '116', label: 'Nkawkaw' },
      { value: '117', label: 'Praso' },
      { value: '111', label: 'Ejisu' },
      { value: '118', label: 'Anyinam' },
      { value: '114', label: 'Mpraeso' },
      { value: '112', label: 'Obo' },
      { value: '113', label: 'Tafo' },
    ],
  },
  {
    id: 'share',
    label: 'Number of Shares as @ 2023 ',
    type: 'text',
    placeholder: '100',
  },
  {
    id: 'price',
    label: 'Share Amount as @ 2023 ',
    type: 'text',
    placeholder: 'GH₵',
  },
  {
    id: 'kin',
    label: 'Next of Kin',
    type: 'text',
    placeholder: 'John Doe',
  },
  {
    id: 'kin-contact',
    label: "Next of Kin's Contact",
    type: 'text',
    placeholder: '233 50254 1210',
  },
  {
    id: 'card',
    label: 'Ghana Card',
    type: 'text',
    placeholder: 'GHA-000123456-7',
    isRequired: false,
  },
  {
    id: 'inputter',
    label: 'Name of Inputer',
    type: 'text',
    placeholder: 'John Doe',
  },
];

export const productInputs = [
  {
    id: 1,
    label: 'Title',
    type: 'text',
    placeholder: 'Apple Macbook Pro',
  },
  {
    id: 2,
    label: 'Description',
    type: 'text',
    placeholder: 'Description',
  },
  {
    id: 3,
    label: 'Category',
    type: 'text',
    placeholder: 'Computers',
  },
  {
    id: 4,
    label: 'Price',
    type: 'text',
    placeholder: '100',
  },
  {
    id: 5,
    label: 'Stock',
    type: 'text',
    placeholder: 'in stock',
  },
];
