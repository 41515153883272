import React, { useState, useEffect } from 'react';
import './profile.scss';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  updateDoc,
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Loading from '../../components/Loading/Loading';
import { sendPasswordResetEmail } from 'firebase/auth';

const Profile = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentStaff, setCurrentStaff] = useState(null);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // State to track selected users
  const [staffs, setStaffs] = useState([]);
  const [adminLogs, setAdminLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;

  useEffect(() => {
    if (auth.currentUser) {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            setCurrentUser(docSnap.data());
          }
        })
        .catch((error) => {
          console.error('Error fetching user:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (auth.currentUser) {
      const staffDocRef = doc(db, 'staff', auth.currentUser.uid);
      getDoc(staffDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            setCurrentStaff(docSnap.data());
          }
        })
        .catch((error) => {
          console.error('Error fetching staff:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    const usersCollection = collection(db, 'users');
    const adminLogsCollection = collection(db, 'adminLogs');

    const pendingUsersQuery = query(
      usersCollection,
      where('status', '==', 'pending')
    );
    const pendingUnsubscribe = onSnapshot(pendingUsersQuery, (snapshot) => {
      if (!isMounted) return;
      const users = snapshot.docs.map((docSnap) => {
        const data = docSnap.data();
        if (data.timeStamp?.toDate) {
          data.formattedDate = data.timeStamp.toDate().toLocaleDateString();
          data.formattedTime = data.timeStamp.toDate().toLocaleTimeString();
        }
        return { id: docSnap.id, ...data };
      });
      setPendingUsers(users);
    });

    const staffsQuery = query(collection(db, 'staff'));
    const staffUnsubscribe = onSnapshot(staffsQuery, (snapshot) => {
      setStaffs(
        snapshot.docs.map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }))
      );
    });

    const logQuery = query(adminLogsCollection, orderBy('timestamp', 'desc'));
    const logUnsubscribe = onSnapshot(logQuery, (snapshot) => {
      setAdminLogs(
        snapshot.docs.map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }))
      );
    });

    return () => {
      isMounted = false;
      pendingUnsubscribe();
      staffUnsubscribe();
      logUnsubscribe();
    };
  }, []);

  const filteredPendingUsers = pendingUsers.filter(
    (user) =>
      user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.membershipCode?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedPendingUsers = filteredPendingUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePasswordReset = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Password reset email sent!');
      })
      .catch((error) => {
        console.error('Error resetting password:', error);
      });
  };

  const handleApproveUser = (userId) => {
    const userRef = doc(db, 'users', userId);
    updateDoc(userRef, { status: 'approved' })
      .then(() => {
        alert('User approved successfully.');
      })
      .catch((error) => {
        console.error('Error approving user:', error);
        alert('Failed to approve user. Please try again.');
      });
  };

  const handleApproveSelectedUsers = () => {
    selectedUsers.forEach((userId) => {
      handleApproveUser(userId);
    });
    setSelectedUsers([]); // Clear selected users after approval
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === displayedPendingUsers.length) {
      setSelectedUsers([]); // Deselect all if already selected
    } else {
      setSelectedUsers(displayedPendingUsers.map((user) => user.id)); // Select all users on the current page
    }
  };

  const handleAddUser = () => {
    navigate('/add-user');
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="profile">
      <Sidebar />
      <div className="profileContainer">
        <Navbar />
        <div className="profileContent">
          <div className="profileHeader">
            <h1>
              {' '}
              Welcome,{' '}
              {`${currentStaff?.name} - ${currentStaff?.role || 'Staff'}`}
            </h1>
            <p>Manage and oversee all user activities from here.</p>
          </div>
          <div className="adminsSection">
            <h2>List of Staffs</h2>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {staffs.map((staff) => (
                  <tr key={staff.id}>
                    <td>{staff.name}</td>
                    <td>{staff.email}</td>
                    <td>{staff.role}</td>
                    <td>
                      <button onClick={() => handlePasswordReset(staff.email)}>
                        Reset Password
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="searchSection">
            <h1>Search Pending For User: </h1>
            <input
              type="text"
              placeholder="Search by name, code or email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="searchField"
            />
            <i className="searchIcon">🔍</i>
          </div>
          <div className="usersSection">
            <h2>List of Pending Shareholders</h2>
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={
                        selectedUsers.length === displayedPendingUsers.length
                      }
                      onChange={handleSelectAllUsers}
                    />
                  </th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Membership Code</th>
                  <th>Account Number</th>
                  <th>Created</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {displayedPendingUsers.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleSelectUser(user.id)}
                      />
                    </td>
                    <td>{user.displayName}</td>
                    <td>{user.email}</td>
                    <td>{user.membershipCode}</td>
                    <td>{user.account}</td>
                    <td>
                      {user.formattedDate} at {user.formattedTime}
                    </td>
                    <td>
                      <button
                        onClick={() => handleApproveUser(user.id)}
                        disabled={user.status === 'approved'}
                        className={
                          user.status === 'approved' ? 'approvedButton' : ''
                        }
                      >
                        {user.status === 'approved' ? 'Approved' : 'Approve'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="bulkActions">
              <button
                onClick={handleApproveSelectedUsers}
                disabled={selectedUsers.length === 0}
              >
                Approve Selected
              </button>
            </div>
            <div className="pagination">
              <button
                onClick={() =>
                  setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                }
                disabled={currentPage === 1}
              >
                ←
              </button>
              <span>{currentPage}</span>
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    Math.min(
                      prevPage + 1,
                      Math.ceil(pendingUsers.length / itemsPerPage)
                    )
                  )
                }
                disabled={
                  currentPage === Math.ceil(pendingUsers.length / itemsPerPage)
                }
              >
                →
              </button>
            </div>
          </div>
          <div className="profileActions">
            <button onClick={handleAddUser} className="addUserButton">
              Add User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
